<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Header: Personal Info -->
        <div class="d-flex my-2">
          <feather-icon
            icon="BookIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            SPORT COMP
          </h4>
        </div>

        <validation-observer ref="simpleRules">
          <!-- Form: Personal Info Form -->
          <b-form class="mt-1">
            <b-row>
              <b-col cols="12">
                <div class="d-flex my-2">
                  <h4
                    class="mb-0"
                    style="font-size: 16px"
                  >
                    COMP Details
                  </h4>
                </div>
                <b-card no-body>
                  <b-card-body>
                    <!-- COMP NAME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>COMP NAME (logged in)</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="comp name"
                          rules="required"
                        >
                          <b-form-input
                            v-model="compName"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!-- COMP NAME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>COMP NAME (not logged in)</span>
                      </b-col>
                      <b-col cols="9">
                        <b-form-input
                          v-model="guestName"
                          type="text"
                        />
                      </b-col>
                    </b-row>
                    <!-- OPEN DATE/TIME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>SHOWDOWN OPEN DATE/TIME</span>
                      </b-col>
                      <b-col cols="9">
                        <flat-pickr
                          id="openDate"
                          v-model="openDate"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:s',
                            enableSeconds: true,
                            disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                          }"
                        />
                      </b-col>
                    </b-row>
                    <!-- COMPETITION DATE/TIME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>FIRST LEG START DATE/TIME</span>
                      </b-col>
                      <b-col cols="9">
                        <flat-pickr
                          id="competitionDate"
                          v-model="competitionDate"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:s',
                            enableSeconds: true,
                            disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                          }"
                        />
                      </b-col>
                    </b-row>

                    <!-- END DATE/TIME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>END DATE/TIME</span>
                      </b-col>
                      <b-col cols="9">
                        <flat-pickr
                          id="endDate"
                          v-model="endDate"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:s',
                            enableSeconds: true,
                            disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                          }"
                        />
                      </b-col>
                    </b-row>
                    <!--PARTICIPANTS DESCRIPTIONS -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>PARTICIPANTS DESCRIPTIONS</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="participants descriptions"
                          rules="required|limitCharacters"
                        >
                          <vue-editor
                            v-model="participantsDes"
                            :editor-toolbar="customToolbar"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!--MORE INFO -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>MORE INFO</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="more info"
                          rules="required"
                        >
                          <b-form-input
                            v-model="moreInfo"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!--REMEMBER -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>REMEMBER</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="remember"
                          rules="required"
                        >
                          <b-form-input
                            v-model="remember"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!--SCRATCHINGS -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>SCRATCHINGS</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="scratchings"
                          rules="required"
                        >
                          <b-form-input
                            v-model="scratchings"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <!-- ENTRY FEE -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>ENTRY FEE</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="price to"
                          rules="required"
                        >
                          <b-form-input
                            v-model="priceTo"
                            min="0"
                            type="number"
                            :no-wheel="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!-- RE BUY FEE -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>RE BUY FEE</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="late change price"
                          rules="required"
                        >
                          <b-form-input
                            v-model="latePrice"
                            min="0"
                            type="number"
                            :no-wheel="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <!-- LIMIT REAL BETSLIP -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>LIMIT BETSLIP</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="limit betslip"
                          rules="required"
                        >
                          <b-form-input
                            id="limit-value"
                            v-model="limitBetslip"
                            :formatter="formatNumberBetslip"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <!-- Manual Betslip entry -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>MANUAL BETSLIP ENTRY (optional)</span>
                      </b-col>
                      <b-col cols="9">
                        <b-form-input
                          id="limit-value"
                          v-model="manualBetSlipEntry"
                          :formatter="formatNumberBetslip"
                        />
                      </b-col>
                    </b-row>
                    <!-- Api Price -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>BONUS ADDITIONAL TICKET (optional)</span>
                      </b-col>
                      <b-col cols="9">
                        <b-form-input
                          v-model="apiPrice"
                          min="0"
                          type="number"
                          placeholder="Enter 100 for 0.5 tickets @ $50"
                          :no-wheel="true"
                        />
                      </b-col>
                    </b-row>

                    <!-- FLEXI -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="12"
                        class="font-weight-bold d-flex justify-content-between mb-1"
                      >
                        <span>FLEXI OPTION</span>
                        <b-button
                          variant="primary"
                          size="md"
                          @click="handleAddMoreOptionFlexi"
                        >
                          + Add More
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <b-row>
                          <b-col
                            v-for="(item, index) in optionsFlexi"
                            :key="index"
                            cols="12"
                            md="4"
                            class="mb-1"
                          >
                            <div class="d-flex justify-content-between mb-1">
                              <span class="d-block"> {{ toCardinal(index + 1) }} Option (%)</span>
                              <b-button
                                variant="danger"
                                size="sm"
                                @click="handleRemoveOptionFlexi"
                              >
                                x
                              </b-button>
                            </div>
                            <validation-provider
                              #default="{ errors }"
                              :name="toCardinal(index + 1) + ' Option'"
                              rules="required"
                            >
                              <b-form-input
                                v-model="optionsFlexi[index]"
                                :formatter="formatNumberFlexi"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                              <!-- </label> -->
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>

                    <!-- Get Prize From -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>Get Prize From </span>
                      </b-col>
                      <b-col cols="9">
                        <b-form-radio-group
                          id="radio-prize"
                          v-model="selectedPrizeFrom"
                          :options="optionsPrizeFrom"
                          name="radio-inline"
                        />
                      </b-col>
                    </b-row>

                    <!-- Front-Prize -->
                    <!-- <b-row v-if="selectedPrizeFrom === 'manual'" class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>Front Prize</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="front prize" rules="required">
                          <b-form-input @click="handleShowModalAddFrontPrizes" :value="frontPrizeValue" readonly />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row> -->

                    <!-- Back-Prize -->
                    <!-- <b-row v-if="selectedPrizeFrom === 'manual'" class="mb-1 d-flex align-items-start">
                      <b-col cols="3" class="font-weight-bold">
                        <span>Back Prize</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider #default="{ errors }" name="back prize" rules="required">
                          <b-form-input @click="handleShowModalAddBackPrizes" :value="backPrizeValue" readonly />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row> -->

                    <!-- Main-Prize -->
                    <b-row
                      v-if="selectedPrizeFrom === 'manual'"
                      class="mb-1 d-flex align-items-start"
                    >
                      <b-col
                        cols="12"
                        class="font-weight-bold d-flex mb-1"
                      >
                        <!-- <span>Main Prize</span> -->
                        <!-- <b-button
                          variant="primary"
                          size="md"
                          @click="handleAddMoreInputMainPrize"
                        >
                          + Add More Input Prize
                        </b-button> -->
                        <input
                          ref="fileInput"
                          type="file"
                          accept=".csv"
                          hidden
                          @change="handleFileUpload"
                        >
                        <b-button
                          v-if="mainPrizes.length === 0"
                          variant="danger"
                          size="md"
                          class="ml-2"
                          @click="onClickInput"
                        >
                          Import File
                        </b-button>
                        <b-button
                          v-if="mainPrizes.length !== 0"
                          variant="danger"
                          size="md"
                          class="ml-2"
                          @click="handleShowReviewList"
                        >
                          View List
                        </b-button>
                        <b-button
                          v-if="mainPrizes.length !== 0"
                          variant="danger"
                          size="md"
                          class="ml-2"
                          @click="handleRemoveAllPrize"
                        >
                          Remove All
                        </b-button>
                      </b-col>
                      <!-- <b-col cols="12">
                        <b-row>
                          <b-col
                            v-for="(item, index) in mainPrizes"
                            :key="index"
                            cols="12"
                            md="4"
                            class="mb-1"
                          >
                            <div class="d-flex justify-content-between mb-1">
                              <span class="d-block"> {{ toCardinal(index + 1) }} Prize</span>
                              <b-button
                                variant="danger"
                                size="sm"
                                @click="handleRemoveMoreInputMainPrize"
                              >
                                x
                              </b-button>
                            </div>
                            <validation-provider
                              #default="{ errors }"
                              :name="toCardinal(index + 1) + ' prize'"
                              rules="required"
                            >
                              <b-form-input
                                v-model="mainPrizes[index]"
                                :formatter="formatNumber"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-col> -->
                    </b-row>
                    <!-- BANNER WEB -->
                    <!-- <b-row>
                      <b-media
                        no-body
                        class="d-flex flex-column"
                      >
                        <b-media-body class="mt-75 d-flex align-items-center mb-75 ml-75">
                          <span class="font-weight-bold mr-75">UPLOAD BANNER WEBSITE</span>
                          <b-button
                            e
                            variant="primary"
                            size="sm"
                            class="mr-75"
                            @click="$refs.refInputEl.$el.click()"
                          >
                            Upload
                          </b-button>
                          <b-form-file
                            ref="refInputEl"
                            accept=".jpg, .png, .gif, .heic, .jpeg"
                            :hidden="true"
                            plain
                            @change="inputImageRenderer"
                          />

                          <b-button
                            variant="outline-secondary"
                            size="sm"
                            class="mr-75"
                            @click="removeImageRenderer"
                          >
                            Reset
                          </b-button>
                        </b-media-body>
                        <b-media-aside>
                          <b-img
                            v-if="webImage"
                            class="banner-img ml-75"
                            :src="webImage"
                            alt="logo"
                          />
                        </b-media-aside>
                      </b-media>
                    </b-row>
                    <small
                      v-if="errorBannerWeb"
                      class="text-danger mb-75"
                    >Please upload banner image</small> -->
                    <!-- BANNER MOBILE -->
                    <!-- <b-row>
                      <b-media
                        no-body
                        class="d-flex flex-column"
                      >
                        <b-media-body class="mt-75 d-flex align-items-center mb-75 ml-75">
                          <span class="font-weight-bold mr-75">UPLOAD BANNER MOBILE</span>
                          <b-button
                            variant="primary"
                            size="sm"
                            class="mr-75"
                            @click="$refs.refInputMobileEl.$el.click()"
                          >
                            Upload
                          </b-button>
                          <b-form-file
                            ref="refInputMobileEl"
                            accept=".jpg, .png, .gif, .heic, .jpeg"
                            :hidden="true"
                            plain
                            @change="inputMobileImageRenderer"
                          />

                          <b-button
                            variant="outline-secondary"
                            size="sm"
                            class="mr-75"
                            @click="removeMobileImageRenderer"
                          >
                            Reset
                          </b-button>
                        </b-media-body>
                        <b-media-aside>
                          <b-img
                            v-if="mobileImage"
                            class="banner-img ml-75"
                            :src="mobileImage"
                            alt="logo"
                          />
                        </b-media-aside>
                      </b-media>
                    </b-row>
                    <small
                      v-if="errorBannerMobile"
                      class="text-danger mb-75"
                    >Please upload banner image</small> -->

                    <!-- FLEXI ROLL OVER POOL -->
                    <b-row class="mb-1 mt-2 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <h4 style="margin-top: 10px">
                          Use Flexi Roll Over Pool
                        </h4>
                      </b-col>
                      <b-col
                        cols="9"
                        style="display: flex; align-items: center; margin-top: 10px"
                      >
                        <b-form-radio-group
                          id="radio-inline-flexi"
                          v-model="selectedFlexiRollOverPool"
                          :options="optionsFlexiRollOverPool"
                          name="radio-inline-flexi"
                        />
                      </b-col>
                    </b-row>

                    <b-row>
                      <template v-if="selectedFlexiRollOverPool">
                        <b-col cols="12">
                          <div style="display: flex; align-items: center">
                            <span
                              style="margin-right: 20px; margin-left: 10px; font-weight: bold; min-width: 160px"
                            >Roll
                              Over Amount($)</span>
                            <b-form-input
                              v-model="flexiRollOverAmount"
                              min="0"
                              type="number"
                              :no-wheel="true"
                              style="max-width: 100px"
                              :formatter="formatPriceRollOver"
                            />
                            <small style="margin-right: 20px; margin-left: 10px; font-weight: bold">Max: {{
                              formatCurrencyBuiltInFunc(flexiRollOverWallet) }}</small>
                          </div>
                        </b-col>
                        <b-col cols="12">
                          <small
                            v-if="errorFlexiRollOverAmount"
                            class="text-danger mb-75 ml-75"
                          >Roll Over Amount is
                            required</small>
                        </b-col>
                      </template>
                    </b-row>

                    <!-- PRIZE TYPE -->
                    <b-row class="mb-1 mt-2 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <h4>Transfer Prize By</h4>
                      </b-col>
                      <b-col cols="9">
                        <b-form-radio-group
                          id="transfer-prize"
                          v-model="selectedTransferPrize"
                          :options="optionsTransferPrizeBy"
                          name="transfer-prize"
                        />
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>

            <div class="d-flex my-2 align-items-center">
              <h4 class="mb-0 mr-2">
                COMP LEGS
              </h4>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="handleApplyAllMarket"
              >
                <template>
                  Apply markets for all events
                </template>
              </b-button>
            </div>

            <p
              class="text-danger"
              style="font-weight: 600"
            >
              * Please select all markets of first event, then click button to apply for all events
            </p>

            <!-- <div class="d-flex my-2">
              <h4 class="mb-0 mr-2">
                Choose sport
              </h4>
              <b-form-radio-group
                id="radio-list-sport"
                v-model="selectedSport"
                :options="optionsSport"
                :disabled="checkDisableSelectSport()"
                name="radio-list-sport"
              />
            </div> -->

            <b-row>
              <b-col
                cols="12"
              >
                <!-- <h6 class="mb-1">MORNING LOCKOUT</h6>
                <flat-pickr id="morningLockout" v-model="morningLockout" class="form-control mb-1" :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d G:i:S K',
                  time_24hr: false,
                  enableSeconds: true,
                  static: true,
                  disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                }" /> -->
                <!-- FRONT ENTRIES -->
                <div
                  v-for="(item, ind) in frontEntryList"
                  :key="ind"
                >
                  <b-row class="mb-1 d-flex align-items-center">
                    <b-col
                      cols="1"
                      class="font-weight-bold"
                    >
                      <span>{{ item.title }}</span>
                    </b-col>
                    <b-col cols="10">
                      <div
                        class="w-full d-flex"
                        style="
                          flex-direction: column;
                          min-height: 38px;
                          padding: 6px 14px;
                          border: 1px solid;
                          border-radius: 6px;
                          white-space: wrap;
                        "
                      >
                        <span
                          v-if="item.value.eventTitle"
                          style="font-weight: bold"
                        > Event: ({{ item.value.league }})</span>
                        <span> {{ item.value.eventTitle }}</span>
                        <span v-if="item.value.startTime"> ( {{ formatDateTimeLocal(item.value.startTime) }} )</span>
                        <div v-if="item.value">
                          <div
                            class="d-flex"
                            style="align-items: center; gap: 5px"
                          >
                            <span class="d-flex align-items-center"> <span style="font-weight: bold">Stadium:</span>
                              <b-form-input
                                v-model="item.value.stadium"
                                type="text"
                                style="padding: 2px; width: 150px; text-align: center; margin-left: 10px;"
                              /></span>
                          </div>
                          <div>
                            <span style="font-weight: bold">Flag:</span>
                            <div
                              v-for="(role, stt) in item.value.scores"
                              :key="role.role"
                              style="margin-bottom: 2px; display: flex; align-items: center"
                            >
                              <span>{{ `${stt + 1}. Role: ${role.role === 'HomeTeam' ? 'Home' : 'Away'}` }}</span>
                              <div class="d-flex flex-column  justify-content-between align-items-center px-2 ">
                                <span>teamAlias: </span>
                                <b-form-input
                                  v-model="role.teamAlias"
                                  type="text"
                                  style="padding: 2px; width: 70px; text-align: center;"
                                />
                              </div>
                              <div
                                v-if="!role.flag"
                                style="cursor: pointer"
                                @click="handleClick(item, role, 'front')"
                              >
                                <feather-icon
                                  icon="UploadIcon"
                                  size="16"
                                  style="margin-left: 8px"
                                /> Upload Team Character
                              </div>
                              <b-img
                                v-if="role.flag"
                                style="width: 30px; height: 30px; cursor: pointer"
                                :src="role.flag"
                                alt="logo"
                                @click="handleClick(item, role, 'front')"
                              />
                              <div
                                v-if="!role.subFlag"
                                style="cursor: pointer"
                                @click="handleClickSubFlag(item, role, 'front')"
                              >
                                <feather-icon
                                  icon="UploadIcon"
                                  size="16"
                                  style="margin-left: 8px"
                                /> Upload Flag
                              </div>
                              <b-img
                                v-if="role.subFlag"
                                style="width: 30px; height: 30px; cursor: pointer; margin-left: 10px;"
                                :src="role.subFlag "
                                alt="logo"
                                @click="handleClickSubFlag(item, role, 'front')"
                              />

                              <b-form-file
                                :ref="`${item.value.eventID}-${role.role}-flag`"
                                accept=".jpg, .png, .gif, .heic, .jpeg"
                                :hidden="true"
                                plain
                                style="cursor: pointer"
                                @change="inputImageRendererFlag"
                              />
                              <b-form-file
                                :ref="`${item.value.eventID}-${role.role}-subflag`"
                                accept=".jpg, .png, .gif, .heic, .jpeg"
                                :hidden="true"
                                plain
                                style="cursor: pointer"
                                @change="inputImageRendererSubFlag"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="d-flex mt-1 mb-1">
                          <span
                            v-if="item.value"
                            style="font-weight: bold"
                          > Markets:</span>
                          <b-button
                            v-if="item.value"
                            variant="primary"
                            class="mb-1 mb-sm-0 add-btn ml-1"
                            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                            @click="handleShowModalDetail(item.value)"
                          >
                            <template> Add more markets </template>
                          </b-button>
                        </div>
                        <span
                          v-for="(i, ind) in item.value.markets"
                          :key="ind"
                        >
                          <span
                            class="d-flex"
                            style="gap: 4px;"
                          >
                            <span>
                              {{ `${ind + 1}, ${i.name}` }}

                            </span>
                            <b-button
                              variant="outline-danger"
                              class="mb-2 mb-sm-0 add-btn"
                              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                              style="min-width: 25px; height: 25px;"
                              @click="handleRemoveMarket(i, item)"
                            >
                              <template> x </template>
                            </b-button>

                            <div
                              v-if="!i.imageUrl"
                              style="cursor: pointer"
                              @click="handleUploadImage(item, i)"
                            >
                              <feather-icon
                                icon="UploadIcon"
                                size="16"
                                style="margin-left: 8px"
                              /> Upload Image
                            </div>
                            <b-img
                              v-if="i.imageUrl"
                              style="width: 25px; height: 25px; cursor: pointer; margin-left: 10px;"
                              :src="i.imageUrl"
                              alt="image"
                              @click="handleUploadImage(item, i)"
                            />
                            <b-form-file
                              :ref="`${item.value.eventID}-${i.externalID}-image`"
                              accept=".jpg, .png, .gif, .heic, .jpeg"
                              :hidden="true"
                              plain
                              style="cursor: pointer"
                              @change="inputImageRendererImageMarket"
                            />

                          </span>
                          <br>
                          <div
                            class="d-flex"
                            style="align-items: center; gap: 5px; margin-top: 3px; margin-bottom: 3px;"
                          >
                            <span style="font-size: 12px">{{ `MarketAlias: ` }}</span>
                            <b-form-input
                              v-model="i.marketAlias"
                              type="text"
                              style="max-width: 70px; height: 30px; text-align: center; padding: 0"
                            />
                            <span style="font-size: 12px;">{{ `Number seleted: ` }}</span>
                            <b-form-input
                              v-model="i.maxSelect"
                              type="number"
                              style="max-width: 30px; height: 30px; text-align: center; padding: 0"
                            />
                            <span style="font-size: 12px">{{ `Market Layout: ` }}</span>
                            <b-form-radio-group
                              :id="`market-${i.externalID}`"
                              v-model="i.marketLayout"
                              :options="optionsUI"
                              :name="`market-${i.externalID}`"
                              style="display: flex; flex-direction: column"
                            />
                          </div>
                        </span>

                        <!-- FEATURED MARKET -->
                        <div
                          v-if="item.value.markets"
                          class="d-flex align-items-center"
                        >
                          <p class="mb-0 mr-2">
                            Featured Market
                          </p>

                          <b-dropdown
                            variant="link"
                            no-caret
                            boundary="viewport"
                          >
                            <template #button-content>
                              <div class="d-flex align-items-center">
                                <div
                                  v-if="item.value.featuredMarket"
                                  class="mr-2"
                                >
                                  {{ item.value.featuredMarket.name }}
                                </div>
                                <b-button
                                  variant="primary"
                                  class="mb-1 mb-sm-0"
                                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                                >
                                  <template>
                                    Choose Market
                                  </template>
                                </b-button>

                              </div>
                            </template>
                            <template>
                              <b-dropdown-item
                                v-for="(i, indx) in item.value.markets"
                                :key="i.number"
                                @click="handleSelectFeatureMarket(i, item)"
                              >
                                {{ `${indx + 1}, ${i.name}` }}
                              </b-dropdown-item>
                            </template>
                          </b-dropdown>
                        </div>
                        <div
                          v-if="item.value.featuredMarket"
                          class="d-flex"
                          style="gap: 4px; align-items: center;"
                        >
                          <p class="mt-1 mr-1">
                            Display Name
                          </p>
                          <b-form-input
                            v-model="item.value.featuredMarket.displayName "
                            type="text"
                            style="max-width: 200px; height: 30px; padding: 0;"
                          />
                        </div>
                      </div>
                    </b-col>
                    <b-col
                      cols="1"
                      class="d-flex"
                    >
                      <b-button
                        v-if="!item.value"
                        variant="primary"
                        class="mb-1 mb-sm-0 add-btn"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        @click="handleCreateLeg"
                      >
                        <template> + </template>
                      </b-button>
                      <b-button
                        v-else
                        variant="primary"
                        class="mb-1 mb-sm-0 remove-btn"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        @click="handleRemoveLegFront(item)"
                      >
                        <template> - </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-col>

            </b-row>

            <b-row>
              <div
                class="mr-4 d-flex align-items-center my-2"
                style="padding: 14px;"
              >
                <h4 class="mb-0 mr-2">
                  Lockout time
                </h4>

                <b-dropdown
                  variant="link"
                  no-caret
                  boundary="viewport"
                >
                  <template #button-content>
                    <div class="d-flex align-items-center">
                      <div
                        v-if="lockLeg"
                        class="mr-2"
                      >
                        {{ formatTime(lockLeg.value.startTime, 'HH:mm aa dd-MM-yyyy') }} (Leg {{ lockLeg.legId }})
                      </div>
                      <b-button
                        variant="primary"
                        class="mb-1 mb-sm-0"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      >
                        <template>
                          Choose leg
                        </template>
                      </b-button>

                    </div>
                  </template>
                  <template>
                    <b-dropdown-item
                      v-for="i in legList"
                      :key="i.number"
                      style="text-align: center"
                      @click="handleSelectLeg(i)"
                    >
                      {{ i.value }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </div>
            </b-row>
            <!-- </b-row> -->

            <b-row class="mt-2">
              <b-col class="d-flex justify-content-end mr-4 mt-4">
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  :disabled="loadingCreateSportComp"
                  @click="handleCreateComp"
                >
                  <Spinner v-if="loadingCreateSportComp" />
                  <template v-else>
                    Create comp
                  </template>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <!-- MODAL ADD PRIZES -->
      <!-- <AddPrizeModal ref="pickPrize" type-pick="front" :data="frontPrizes"
        @on-update-front-prize="handleUpdateFrontPrizes" :isEdit="true" />
      <AddPrizeModal ref="pickPrizeBack" type-pick="back" :data="backPrizes"
        @on-update-back-prize="handleUpdateBackPrizes" :isEdit="true" /> -->
    </b-card>

    <!-- MODAL LIST EVENT -->
    <b-modal
      ref="add-sport-leg"
      hide-footer
      title="Choose event"
      centered
      cancel-variant="outline-secondary"
      size="xl"
      @hide="handleHideModal"
    >
      <b-row class="text-modal">
        <b-col cols="12">
          <ListEvent
            :list-detail-event-prop="listDetailEventProp"
            :selected-sport="selectedSport"
            @create-leg="handleSelectEvent"
          />
        </b-col>
      </b-row>
    </b-modal>

    <!-- MODAL DETAIL EVENT -->
    <b-modal
      ref="detail-event"
      title="List Market"
      centered
      cancel-variant="outline-secondary"
      hide-footer
      size="xl"
    >
      <SelectDetaiEvent
        :event-id="eventIdSelected"
        :markets="listMarketSelected"
        @update-market="handleAddMarket"
      />
      <template #modal-ok>
        <span>Save</span>
      </template>
    </b-modal>

    <!-- MODAL REVIEW LIST PRIZES -->
    <b-modal
      ref="list-review"
      hide-footer
      title="Review list"
      centered
      cancel-variant="outline-secondary"
      size="lg"
    >
      <b-col cols="12">
        <b-row>
          <b-col
            v-for="(item, index) in mainPrizes"
            :key="index"
            cols="12"
            md="3"
            class="mb-1"
          >
            <!-- <label for="" > -->

            <div class="d-flex justify-content-between mb-1">
              <span
                class="d-block"
                style="color: black;"
              > {{ toCardinal(index + 1) }} Prize</span>
              <b-button
                v-if="index === mainPrizes.length -1"
                variant="danger"
                size="sm"
                @click="handleRemoveMoreInputMainPrize"
              >
                x
              </b-button>
            </div>
            <validation-provider
              #default="{ errors }"
              :name="toCardinal(index + 1) + ' prize'"
              rules="required"
            >
              <b-form-input
                v-model="mainPrizes[index]"
                :formatter="formatNumber"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <!-- </label> -->
            </validation-provider>
          </b-col>
        </b-row>
      </b-col>

    </b-modal>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  VBModal,
  BCardBody,
  BFormInput,
  BButton,
  // BFormTextarea,
  // BFormTags,
  // BInputGroup,
  // BMedia,
  // BMediaAside,
  // BMediaBody,
  BImg,
  BFormFile,
  BFormRadioGroup,
  BDropdown,
  BDropdownItem,
  // BFormGroup,
} from 'bootstrap-vue'
import useToastHandler from '@/services/toastHandler'
// import vSelect from 'vue-select'
import { required, integer, limitCharacters } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import flatPickr from 'vue-flatpickr-component'
import {
  formatDate,
  convertTZ,
  getObjectChange,
  formatDateUTC,
  formatDateSend,
  formatDateTimeLocal,
  formatCurrencyBuiltInFunc,
  formatDateTimeAustralia, toCardinal,
  formatTime,
  formatMarketAlias,
} from '@/helpers/utils'
import { VueEditor } from 'vue2-editor'

import { mapActions, mapState } from 'vuex'
import Spinner from '@core/components/Spinner'
import { subDays, subSeconds } from 'date-fns'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
// import AddPrizeModal from '../../comp/create-comp/AddPrizeModal.vue'
import { parse } from 'csv-parse'
import ListEvent from './ListEvent.vue'
import SelectDetaiEvent from './SelectDetaiEvent.vue'

export default {
  components: {
    // AddPrizeModal,
    ListEvent,
    SelectDetaiEvent,
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    // VBModal,
    BCardBody,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    // BFormTextarea,
    // vSelect,
    flatPickr,
    VueEditor,
    // BFormTags,
    Spinner,
    // BInputGroup,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    BImg,
    BFormFile,
    BFormRadioGroup,
    // BFormGroup,
    BDropdown,
    BDropdownItem,

  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      toCardinal,
      formatDateTimeAustralia,
      errorFrontLeg: false,
      // errorBackLeg: false,
      // errorPrizes: false,
      errorBannerWeb: false,
      errorBannerMobile: false,
      frontEntryList: [
        {
          legId: 1, type: 'front', title: 'LEG 1', value: '',
        },
        {
          legId: 2, type: 'front', title: 'LEG 2', value: '',
        },
        {
          legId: 3, type: 'front', title: 'LEG 3', value: '',
        },
        {
          legId: 4, type: 'front', title: 'LEG 4', value: '',
        },
        {
          legId: 5, type: 'front', title: 'LEG 5', value: '',
        },
        {
          legId: 6, type: 'front', title: 'LEG 6', value: '',
        },
        {
          legId: 7, type: 'front', title: 'LEG 7', value: '',
        },
        {
          legId: 8, type: 'front', title: 'LEG 8', value: '',
        },
      ],
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ color: [] }],
      ],
      // frontPrizes: [],
      // backPrizes: [],
      mainPrizes: [],
      compName: '',
      percentWinner: '',
      openDate: new Date(),
      competitionDate: new Date(),
      endDate: new Date(),
      morningLockout: new Date(),
      afternoonLockout: new Date(),
      participantsDes: '<p>Showdown Lockout is at 12:49 pm NSW Time on Thursday 30th May 2024</p><p>Rebuy Opens at 12:49 pm NSW Time on Thursday 30th May 2024</p><p>Rebuy Closes at 1:59 pm NSW Time on Thursday 30th May 2024</p><p>--------------------------------------------------</p><p>For this week’s XXX Showdown, we have selected 12 legs across YYY, ZZZ &amp; AAA</p><p>A $50 bet gets you into the Showdown – a 12 leg racecard where you earn points for your horse winning or placing, with points earnt based on the top tote dividends for both win and place. Follow the leaderboard and the players with the most points at the end of the day share the massive prizes</p><p>Someone wins BIG every week, as there are NO jackpots</p><p>Check out the How To Play video for an easy way to learn</p><p>For full T&amp;C’s &amp; Betting Rules click on the tabs within the app or the website</p>',
      moreInfo:
        'https://upcprize-prod.s3.ap-southeast-2.amazonaws.com/Legal/Showdown+Rules+Published+Version+5+September+2024.pdf',
      remember: 'We pay best tote odds based on official dividends. Dead heat rules apply.',
      scratchings:
        'You will be automatically allocated the lowest numbered runner. Eg runner 1 is the lowest numbered non scratched runner.',

      priceTo: 50,
      latePrice: 15,
      disableDate: subDays(new Date(), 1),

      // webImage: null,
      // fileImage: null,
      mobileImage: null,
      fileMobileImage: null,

      selectedPrizeFrom: 'api',
      optionsPrizeFrom: [
        { text: 'API', value: 'api' },
        { text: 'Manual', value: 'manual' },
      ],

      selectedTransferPrize: 'Cash',
      optionsTransferPrizeBy: [
        { text: 'Cash', value: 'Cash' },
        { text: 'Bonus-Cash', value: 'Bonus-Cash' },
      ],

      optionsFlexi: [],

      optionsFlexiRollOverPool: [
        { text: 'No', value: false },
        { text: 'Yes', value: true },
      ],
      selectedFlexiRollOverPool: false,
      apiPrice: null,
      // errorAPIPrice: false,
      flexiRollOverAmount: null,
      errorFlexiRollOverAmount: false,

      limitBetslip: null,
      manualBetSlipEntry: null,
      guestName: null,

      optionsSport: [
        {
          text: 'AFL',
          value: 'AFL',
        },
        {
          text: 'NRL',
          value: 'NRL',
        },
        // {
        //   text: 'NBA',
        //   value: 'NBA',
        // },
        // {
        //   text: 'BBL',
        //   value: 'BBL',
        // },
        // {
        //   text: 'NFL',
        //   value: 'NFL',
        // },
      ],
      selectedSport: 'AFL',
      listDetailEventProp: [],

      flagSelected: null,
      subFlagSelected: null,
      loadingCreateSportComp: false,

      legList: [],
      lockLeg: null,

      eventIdSelected: null,
      listMarketSelected: [],

      optionsUI: [
        { text: '1 Line', value: 'line' },
        { text: '1 column', value: 'list' },
        { text: '2 column', value: 'column' },
        { text: '1 column no flag', value: 'one-column-no-flag' },
      ],
      imageMarketSelected: null,
    }
  },

  computed: {
    ...mapState('races/createRace', ['loading', 'dataPrizes', 'imageWebUrl', 'imageMobileUrl']),
    ...mapState('races/flexiRollOver', ['flexiRollOverWallet']),
    // frontPrizeValue() {
    //   if (!this.frontPrizes) return ''
    //   return this.frontPrizes.join(', ')
    // },
    // backPrizeValue() {
    //   if (!this.backPrizes) return ''
    //   return this.backPrizes.join(', ')
    // },
  },
  watch: {
    frontEntryList(val) {
      if (val.find(item => item.value)) this.errorFrontLeg = false
      const list = val.filter(i => i.value)
      this.legList = list.map(i => ({
        number: i.legId, value: i.title,
      }))
    },
    // backEntryList(val) {
    //   if (val.find(item => item.value)) this.errorBackLeg = false
    // },
    // webImage(val) {
    //   if (val) this.errorBannerWeb = false
    // },
    // mobileImage(val) {
    //   if (val) this.errorBannerMobile = false
    // },
    flexiRollOverAmount(val) {
      if (val && this.selectedFlexiRollOverPool) this.errorFlexiRollOverAmount = false
    },
    selectedFlexiRollOverPool(val) {
      if (!val) {
        this.errorFlexiRollOverAmount = false
      }
    },
    sportName(val) {
      if (val !== this.selectedSport) this.selectedSport = val
    },
  },
  async mounted() {
    this.resetListPrizes()
    await this.getFlexiRollOverWallet()
  },

  methods: {
    ...mapActions({
      uploadImage: 'races/createRace/uploadImage',
      uploadImageMobile: 'races/createRace/uploadImageMobile',
      resetListPrizes: 'races/createRace/reset',
      getFlexiRollOverWallet: 'races/flexiRollOver/getFlexiRollOverWallet',
      createSportComp: 'sportComp/createComp/createSportComp',
      handleAddLeagueToComp: 'sportComp/createComp/addLeagueToComp',
      handleAddSportEventsToComp: 'sportComp/createComp/addSportEventsToComp',
      handleAddSportMarketsToComp: 'sportComp/createComp/addSportMarketsToComp',
      uploadFlag: 'sportComp/createComp/uploadFlag',
      getDetail: 'sportComp/eventDetail/getDetail',
    }),
    handleAddMoreInputMainPrize() {
      this.mainPrizes.push('')
    },
    handleRemoveMoreInputMainPrize() {
      this.mainPrizes.pop()
    },
    // handleShowModalAddFrontPrizes() {
    //   this.$refs['pickPrize'].openModal()
    // },
    // handleShowModalAddBackPrizes() {
    //   this.$refs['pickPrizeBack'].openModal()
    // },
    async handleCreateComp() {
      const valid = await this.$refs.simpleRules.validate()
      if (!valid) return
      // if (!this.webImage) {
      //   this.errorBannerWeb = true
      // }
      // if (!this.mobileImage) {
      //   this.errorBannerMobile = true
      // }

      const list = this.frontEntryList
      const formatListHasLeg = list.filter(i => i.value)

      const checkErr = formatListHasLeg.find(
        i => i.value.markets.some(market => !market.maxSelect)
          || !i.value.stadium
          || i.value.scores.some(role => !role.flag)
          || !i.value.featuredMarket,
      )
      if (checkErr && formatListHasLeg.length > 0) return this.showToastError('Please add detail event and select featured market', 'Error')

      if (this.selectedFlexiRollOverPool && !this.flexiRollOverAmount) this.errorFlexiRollOverAmount = true
      if (this.errorFlexiRollOverAmount) return

      this.loadingCreateSportComp = true

      // const formattedFrontPrize = this.frontPrizes.map((item, index) => ({
      //   order: index + 1,
      //   value: +item,
      //   type: 'front',
      // }))
      // const formattedBackPrize = this.backPrizes.map((item, index) => ({
      //   order: index + 1,
      //   value: +item,
      //   type: 'back',
      // }))
      const formattedMainPrize = this.mainPrizes.map((item, index) => ({
        order: index + 1,
        value: +item,
        type: 'main',
      }))
      if (this.selectedPrizeFrom === 'manual' && !formattedMainPrize.length) {
        return this.showToastError('Please enter main prizes', 'Error')
      }

      const listPrizes = formattedMainPrize
      const formatListFlexi = this.optionsFlexi.map(i => Number(i))

      const data = {
        name: this.compName,
        ...(this.guestName && { guestName: this.guestName }),
        moreInfo: this.moreInfo,
        openForEntry: this.formatDateSend(this.openDate),
        competitionDate: this.formatDateSend(this.competitionDate),
        endDate: this.formatDateSend(this.endDate),
        participantDescriptions: this.participantsDes,
        remember: this.remember,
        scratchings: this.scratchings,
        price: this.priceTo,
        latePrice: this.latePrice,
        morningLockout: this.formatDateSend(subSeconds(new Date(this.endDate), 40)),
        afternoonLockout: this.formatDateSend(subSeconds(new Date(this.endDate), 30)),
        bannerUrl: this.imageWebUrl,
        bannerMobileUrl: this.imageMobileUrl,
        prizeMode: this.selectedPrizeFrom,
        ...(formatListFlexi.length > 0 && { flexiValues: formatListFlexi }),
        useFlexiRollOverWallet: this.selectedFlexiRollOverPool ? 1 : 0,
        apiPrice: Number(this.apiPrice),
        ...(this.selectedFlexiRollOverPool && { flexiRollOverAmount: Number(this.flexiRollOverAmount) }),
        joinTimesLimit: Number(this.limitBetslip),
        ...(this.manualBetSlipEntry && { manualBetSlipEntry: Number(this.manualBetSlipEntry) }),
        prizeType: this.selectedTransferPrize,
        lockoutLeg: this.lockLeg ? this.lockLeg.value.startTime : this.formatDateSend(subSeconds(new Date(this.endDate), 30)),
        status: 'draft',
      }

      const res = await this.createSportComp({
        prizes: listPrizes,
        data,
      })
      if (!res) {
        this.loadingCreateSportComp = false
        return
      }

      const competitionId = res.competitionId
      if (competitionId && formatListHasLeg.length === 0) {
        await this.handleAddLeagueToComp({
          competitionId,
          sportLeagueId: 'AFL',
        })
        this.showToastSuccess('Success', 'Create competition successfully!')
        this.$router.push({ name: 'comp-list' })
        this.loadingCreateSportComp = false
        return
      }
      if (res.competitionId && formatListHasLeg.length > 0) {
        const resLeague = await this.handleAddLeagueToComp({
          competitionId,
          sportLeagueId: formatListHasLeg[0].value.league,
        })
        if (resLeague.sportLeagueId) {
          const formatListLeg = []

          if (formatListHasLeg.length === 0) {
            this.showToastSuccess('Success', 'Create competition successfully!')
            this.$router.push({ name: 'comp-list' })
            this.loadingCreateSportComp = false
            return
          }

          // eslint-disable-next-line no-unused-vars
          formatListHasLeg.forEach((leg, ind) => {
            if (leg.value) {
              const dataItemFormat = leg.value
              const value = {
                eventId: dataItemFormat.eventID,
                eventTitle: dataItemFormat.eventTitle,
                startTime: dataItemFormat.startTime,
                sport: dataItemFormat.sport,
                country: dataItemFormat.country,
                league: dataItemFormat.league,
                primaryMarketName: dataItemFormat.primaryMarketName ?? '',
                displayStatus: dataItemFormat.displayStatus,
                bettingStatus: dataItemFormat.bettingStatus,
                detailLink: dataItemFormat.detailLink ?? '',
                brandLinksLadbrokes: dataItemFormat.brandLinks.ladbrokes,
                brandLinksNeds: dataItemFormat.brandLinks.neds,
                eventType: dataItemFormat.eventType,
                entryType: 0,
                orderNumber: dataItemFormat.number,
                stadium: dataItemFormat.stadium,
                scores: dataItemFormat.scores,
              }
              formatListLeg.push(value)
            }
          })

          const resEvent = await this.handleAddSportEventsToComp({
            competitionId,
            events: formatListLeg,
          })
          if (resEvent) {
            // eslint-disable-next-line no-restricted-syntax
            for (const leg of formatListHasLeg) {
              const featuredMarket = leg.value.featuredMarket
              const listMarkets = leg.value.markets.map((market, ind) => {
                const formatSelectionList = market.selections.map(sel => ({
                  name: sel.name,
                  role: sel.role,
                  winPrice: sel.winPrice,
                  marketSelectionId: sel.externalID,
                  ...(sel.handicap && { handicap: sel.handicap }),
                }))
                return {
                  name: market.name,
                  startTime: market.startTime,
                  marketType: market.marketType,
                  selectionLimit: market.maxSelect,
                  marketAlias: market.marketAlias,
                  selections: formatSelectionList,
                  marketId: market.externalID,
                  orderNumber: ind + 1,
                  ...(market.handicap && { handicap: market.handicap }),
                  marketLayout: market.marketLayout,
                  isFeatured: market.externalID === featuredMarket.externalID,
                  ...(market.externalID === featuredMarket.externalID && { displayName: featuredMarket.displayName }),
                  ...(market.imageUrl && { imageUrl: market.imageUrl }),

                }
              })
              // eslint-disable-next-line no-await-in-loop
              await this.handleAddSportMarketsToComp({
                competitionId,
                eventId: leg.value.eventID,
                markets: listMarkets,
              })
            }

            this.showToastSuccess('Success', 'Create competition successfully!')
            this.loadingCreateSportComp = false
            this.$router.push({ name: 'comp-list' })
          } else {
            this.loadingCreateSportComp = false
          }
        } else {
          this.loadingCreateSportComp = false
        }
      }
    },
    formatNumberBetslip(value) {
      let val = value.replace(/[^0-9.]/g, '')
      if (!Number.isInteger(Number(val))) {
        val = ''
      }
      const parts = val.split('.')
      parts[0] = parts[0].replace(/^0+/, '')
      val = parts.join('.')
      const decimalRegex = /^\d*\.?\d*$/
      if (!decimalRegex.test(val)) {
        val = ''
      }
      const numericVal = parseFloat(val)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(numericVal)) {
        val = ''
      } else if (numericVal < 1) {
        val = '1'
      }
      return val
    },

    formatNumber(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return value.slice(0, value.length - 1)
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    formatNumberFlexi(value) {
      const regexNumber = /^[0-9.]+$/

      if (Number(value) >= 100) return ''
      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return value.slice(0, value.length - 1)
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    // async inputImageRenderer(evt) {
    //   const formData = new FormData()
    //   const file = evt.target.files[0]
    //   if (file && file.size <= 10 * 1024 * 1024) {
    //     this.fileImage = file

    //     useInputImageRenderer(evt.target, base64 => {
    //       this.webImage = base64
    //     })
    //     formData.append('image', file)
    //     await this.uploadImage(formData)
    //   } else {
    //     this.showToastError('Error', 'The file is less than or equal to 10MB')
    //   }
    // },
    // removeImageRenderer() {
    //   this.webImage = null
    // },
    // async inputMobileImageRenderer(evt) {
    //   const formData = new FormData()
    //   const file = evt.target.files[0]
    //   if (file && file.size <= 10 * 1024 * 1024) {
    //     this.fileMobileImage = file

    //     useInputImageRenderer(evt.target, base64 => {
    //       this.mobileImage = base64
    //     })
    //     formData.append('image', file)
    //     await this.uploadImageMobile(formData)
    //   } else {
    //     this.showToastError('Error', 'The file is less than or equal to 10MB')
    //   }
    // },
    // removeMobileImageRenderer() {
    //   this.mobileImage = null
    // },
    // handleUpdateFrontPrizes(data) {
    //   this.frontPrizes = data
    // },
    // handleUpdateBackPrizes(data) {
    //   this.backPrizes = data
    // },
    handleAddMoreOptionFlexi() {
      this.optionsFlexi.push('')
    },
    handleRemoveOptionFlexi() {
      this.optionsFlexi.pop()
    },
    formatPriceRollOver(value) {
      if (value < 0) return null
      if (value > this.flexiRollOverWallet) return this.flexiRollOverWallet
      return value
    },
    handleHideModal() {
      this.$refs['add-sport-leg'].hide()
    },
    handleCreateLeg() {
      this.$refs['add-sport-leg'].show()
    },
    handleSelectEvent(data) {
      this.listDetailEventProp = data
      this.frontEntryList = this.frontEntryList.map(i => {
        const item = data.find(dataItem => dataItem.number === i.legId)
        if (!item) {
          return {
            ...i,
            value: '',
          }
        }
        const formatItem = {
          ...item,
          stadium: item.venue,
          scores: item.scores,
          markets: item.markets.map(h => {
            if (!i.value.markets) return h
            const checkItem = i.value.markets.find(y => y.name === h.name)
            return {
              ...h,
              maxSelect: checkItem && checkItem.maxSelect ? checkItem.maxSelect : null,
              marketAlias: checkItem && checkItem.marketAlias ? checkItem.marketAlias : formatMarketAlias(checkItem.name),
            }
          }),
        }
        return {
          ...i,
          value: formatItem,
        }
      })

      this.handleHideModal()
    },
    handleRemoveLegBack(item) {
      this.listDetailEventProp = this.listDetailEventProp.filter(i => i.eventID !== item.value.eventID)
    },
    handleRemoveLegFront(item) {
      this.listDetailEventProp = this.listDetailEventProp.filter(i => i.eventID !== item.value.eventID)
      this.frontEntryList = this.frontEntryList.map(i => {
        if (item.legId === i.legId) {
          return {
            ...i,
            value: '',
          }
        }
        return i
      })
    },
    handleClick(item, role, type) {
      const eventID = item.value.eventID
      const formatItem = {
        type,
        eventID,
        role: role.role,
      }
      this.flagSelected = formatItem
      const element = this.$refs[`${eventID}-${role.role}-flag`][0]
      if (element) {
        // eslint-disable-next-line no-unused-expressions
        element?.$el?.click()
      }
    },
    handleClickSubFlag(item, role, type) {
      const eventID = item.value.eventID
      const formatItem = {
        type,
        eventID,
        role: role.role,
      }
      this.subFlagSelected = formatItem
      const element = this.$refs[`${eventID}-${role.role}-subflag`][0]
      if (element) {
        // eslint-disable-next-line no-unused-expressions
        element?.$el?.click()
      }
    },
    async inputImageRendererFlag(evt) {
      const formData = new FormData()
      const file = evt.target.files[0]
      if (file && file.size <= 10 * 1024 * 1024) {
        // eslint-disable-next-line no-unused-vars
        useInputImageRenderer(evt.target, base64 => { })
        formData.append('image', file)
        const res = await this.uploadFlag(formData)

        if (res) {
          if (this.flagSelected && this.flagSelected.type === 'front') {
            this.frontEntryList = this.frontEntryList.map(leg => {
              if (this.flagSelected.eventID === leg.value.eventID) {
                const scores = leg.value.scores.map(i => {
                  if (this.flagSelected.role === i.role) {
                    return {
                      ...i,
                      flag: res,
                    }
                  }
                  return i
                })
                return {
                  ...leg,
                  value: {
                    ...leg.value,
                    scores,
                  },
                }
              }
              return leg
            })
          }
        }
      } else {
        this.showToastError('Error', 'The file is less than or equal to 10MB')
      }
    },
    async inputImageRendererSubFlag(evt) {
      const formData = new FormData()
      const file = evt.target.files[0]
      if (file && file.size <= 10 * 1024 * 1024) {
        // eslint-disable-next-line no-unused-vars
        useInputImageRenderer(evt.target, base64 => { })
        formData.append('image', file)
        const res = await this.uploadFlag(formData)

        if (res) {
          if (this.subFlagSelected && this.subFlagSelected.type === 'front') {
            this.frontEntryList = this.frontEntryList.map(leg => {
              if (this.subFlagSelected.eventID === leg.value.eventID) {
                const scores = leg.value.scores.map(i => {
                  if (this.subFlagSelected.role === i.role) {
                    return {
                      ...i,
                      subFlag: res,
                    }
                  }
                  return i
                })
                return {
                  ...leg,
                  value: {
                    ...leg.value,
                    scores,
                  },
                }
              }
              return leg
            })
          }
        }
      } else {
        this.showToastError('Error', 'The file is less than or equal to 10MB')
      }
    },
    handleUploadImage(item, market) {
      const eventID = item.value.eventID
      const formatItem = {
        marketId: market.externalID,
        eventID,
      }
      this.imageMarketSelected = formatItem
      const element = this.$refs[`${eventID}-${market.externalID}-image`][0]
      if (element) {
        // eslint-disable-next-line no-unused-expressions
        element?.$el?.click()
      }
    },

    async inputImageRendererImageMarket(evt) {
      const formData = new FormData()
      const file = evt.target.files[0]
      if (file && file.size <= 10 * 1024 * 1024) {
        // eslint-disable-next-line no-unused-vars
        useInputImageRenderer(evt.target, base64 => { })
        formData.append('image', file)
        const res = await this.uploadFlag(formData)
        if (res) {
          if (this.imageMarketSelected) {
            this.frontEntryList = this.frontEntryList.map(leg => {
              if (this.imageMarketSelected.eventID === leg.value.eventID) {
                const markets = leg.value.markets.map(i => {
                  if (this.imageMarketSelected.marketId === i.externalID) {
                    return {
                      ...i,
                      imageUrl: res,
                    }
                  }
                  return i
                })
                return {
                  ...leg,
                  value: {
                    ...leg.value,
                    markets,
                  },
                }
              }
              return leg
            })
          }
        }
      } else {
        this.showToastError('Error', 'The file is less than or equal to 10MB')
      }
    },
    handleSelectLeg(i) {
      const findItem = this.frontEntryList.find(fr => fr.legId === i.number)
      this.lockLeg = findItem
    },

    handleShowModalDetail(item) {
      this.eventIdSelected = item.eventID
      this.$refs['detail-event'].show()
      this.listMarketSelected = item.markets
    },
    handleAddMarket(data) {
      this.$refs['detail-event'].hide()
      if (data.length > 0) {
        const formatList = data.map(i => ({
          ...i,
          marketAlias: formatMarketAlias(i.name),
          maxSelect: 1,
          marketLayout: 'list',
        }))
        this.frontEntryList = this.frontEntryList.map(i => {
          if (i.value.eventID === this.eventIdSelected) {
            return {

              ...i,
              value: {
                ...i.value,
                markets: [...i.value.markets, ...formatList],
              },
            }
          }
          return i
        })
      }
    },
    handleRemoveMarket(x, item) {
      this.frontEntryList = this.frontEntryList.map(i => {
        if (i.value.eventID === item.value.eventID) {
          return {
            ...i,
            value: {
              ...i.value,
              markets: i.value.markets.filter(market => market.externalID !== x.externalID),
            },
          }
        }
        return i
      })
    },
    async  getListMarketEvent(eventID) {
      // eslint-disable-next-line no-return-await
      return await this.getDetail(eventID).markets
    },
    handleApplyAllMarket() {
      const listMarketFirstEvent = this.frontEntryList?.[0].value.markets

      const newList = this.frontEntryList
        .map(async i => {
          if (i.legId === 1 || !i.value) return i
          const list = []
          const res = await this.getDetail(i.value.eventID)
          const getListMarket = res.markets
          listMarketFirstEvent.forEach(market => {
            const findItem = getListMarket.find(ma => ma.name === market.name)
            if (findItem) {
              list.push({
                ...findItem,
                maxSelect: market.maxSelect,
                marketAlias: formatMarketAlias(market.name),
                marketLayout: market.marketLayout,
              })
              i.value.markets = list
            }
          })
          return i
        })

      Promise.all(newList).then(result => {
        this.frontEntryList = result
        this.showToastSuccess('Success', 'Auto pick complete all events successfully!')
      })
    },
    handleSelectFeatureMarket(i, item) {
      this.frontEntryList = this.frontEntryList.map(entry => {
        if (entry.value.eventID === item.value.eventID) {
          return {
            ...entry,
            value: {
              ...entry.value,
              featuredMarket: {
                ...i, displayName: '',
              },
            },
          }
        }
        return entry
      })
    },
    onClickInput() {
      const fileInput = this.$refs.fileInput
      fileInput.click()
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = () => {
        this.parseCSV(reader.result)
      }
      reader.readAsText(file)
      this._resetInputFile()
    },
    parseCSV(csvData) {
      parse(csvData, { delimiter: ',' }, (err, data) => {
        if (err) {
          console.error(err)
          return
        }
        data.shift()
        const newList = data.map(i => i[1])
        this.mainPrizes = newList
      })
    },
    handleRemoveAllPrize() {
      this.mainPrizes = []
    },
    handleShowReviewList() {
      this.$refs['list-review'].show()
    },
    _resetInputFile() {
      this.$refs.fileInput.value = null
    },
  },
  setup(_, { emit }) {
    const { showToastSuccess, showToastError } = useToastHandler()
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => { })

    const onSubmit = () => {
      emit('next-step')
    }

    return {
      showToastSuccess,
      showToastError,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      limitCharacters,
      required,
      integer,
      convertTZ,
      formatDate,
      getObjectChange,
      formatDateUTC,
      formatDateSend,
      formatDateTimeLocal,
      formatCurrencyBuiltInFunc,
      formatTime,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.text-modal {
  color: black !important;
}

.banner-img {
  max-width: 100%;
}

.add-btn {
  padding: 2px 4px;
}

.remove-btn {
  padding: 0px 4px 2px 4px;
  margin-left: 1px;
}

.delete-btn {
  color: red;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.text-modal {
  color: black;
}

.modal.modal-footer {
  display: none !important;
}
</style>
